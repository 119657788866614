<template>
    <div id="page">
        <el-card>
            <div class="search-content">
                <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" label-width="68px">
                    <el-form-item label="拍摄码" prop="qrCode">
                        <el-input v-model="queryParams.qrCode" placeholder="拍摄码" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="照片状态" prop="photoStatus">
                        <el-select v-model="queryParams.photoStatus" placeholder="请选择照片状态" clearable>
                            <el-option
                                v-for="dict in photoStatusList"
                                :key="dict.dictValue"
                                :label="dict.dictLabel"
                                :value="dict.dictValue"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="照片类型" prop="photoType">
                        <el-select v-model="queryParams.photoType" placeholder="请选择照片状态" clearable>
                            <el-option
                                v-for="dict in photoTypeOptions"
                                :key="dict.value"
                                :label="dict.label"
                                :value="dict.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上传时间" prop="daterangeUploadTime">
                        <el-date-picker
                            v-model="daterangeUploadTime"
                            style="width: 240px"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" size="mini"
                                   @click="handleQuery(true)">搜索
                        </el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>

        <el-card>
            <div class="opt-btn">
                <el-button type="primary" @click="handleAdd" size="mini" icon="el-icon-edit">上传照片</el-button>
            </div>
            <el-table :data="tableData" v-loading="loading" border style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column prop="id" align="center" label="id" width="200">
                </el-table-column>
                <el-table-column prop="qrCode" align="center" label="拍摄码" width="300">
                </el-table-column>
                <el-table-column label="原始照片" align="center" prop="oriFilePath" width="100">
                    <template slot-scope="scope">
                        <ImagePreview width="72px" height="82px" :src="(scope.row.oriFilePath)">
                        </ImagePreview>
                    </template>
                </el-table-column>
                <el-table-column label="处理后照片" align="center" prop="zzFilePath" width="100">
                    <template slot-scope="scope">
                        <ImagePreview width="72px" height="82px" :src="(scope.row.zzFilePath)">
                        </ImagePreview>
                    </template>
                </el-table-column>
                <el-table-column label="照片类型" align="center" prop="photoTypeText" width="300">
                </el-table-column>
                <el-table-column label="原因" align="center" prop="reason" width="300">
                </el-table-column>
                <el-table-column label="照片状态" align="center" prop="photoStatus" width="300">
                    <template slot-scope="scope">
                        <dict-tag :options="photoStatusList" :value="scope.row.photoStatus" :tip="getTip(scope.row)"/>
                    </template>
                </el-table-column>
                <el-table-column prop="uploadTime" align="center" label="上传时间" width=""></el-table-column>
                <el-table-column prop="kzxTime" align="center" label="入库时间" width=""></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="showDetail(scope.row)" icon="el-icon-edit">详细</el-button>
                        <el-button v-if="scope.row.photoStatus==99 && scope.row.photoType==501" size="mini" @click="printBox(scope.row)" icon="el-icon-edit">打印回执</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-card style="display: flex;justify-content: right">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="queryParams.pageNum" :page-sizes="[10, 20, 30, 50]"
                           :page-size="queryParams.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <el-dialog title="详细" :visible.sync="openDetail" width="600px" append-to-body>
            <el-form ref="form" :model="detailForm" label-width="80px">
                <el-form-item label="照片状态" prop="qrCode">
                    <el-input v-model="detailForm.photoStatusText" readonly/>
                </el-form-item>
                <el-form-item label="拍摄码" prop="qrCode">
                    <el-input v-model="detailForm.qrCode" readonly/>
                </el-form-item>
                <el-form-item label="失败原因" prop="qrCode">
                    <el-input v-model="detailForm.reason" readonly/>
                </el-form-item>
                <el-form-item label="上传时间" prop="qrCode">
                    <el-input v-model="detailForm.uploadTime" readonly/>
                </el-form-item>
                <el-form-item label="入库时间" prop="qrCode">
                    <el-input v-model="detailForm.kzxTime" readonly/>
                </el-form-item>
                <el-form-item label="照片" prop="qrCode">
                    <ImagePreview width="72px" height="82px" :src="(detailForm.zzFilePath)">
                    </ImagePreview>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="拍摄码" prop="qrCode">
                                        <el-input v-model="form.qrCode" placeholder="拍摄码" maxlength="8" clearable/>
                    <!--                    <CodeInput :codeValue.sync="qrCode" />-->
<!--                    <CodeInput :codeValue.sync="form.qrCode"/>-->
                </el-form-item>
                <el-form-item label="" prop="">
                    若终端设备损坏，可联系客服获取拍摄码。客服电话：139 1805 6768
                </el-form-item>

                <el-form-item label="照片类型" prop="photoType">
                    <el-select v-model="form.photoType" placeholder="请选择">
                        <el-option
                            v-for="item in photoTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择照片" prop="pic">
                    <el-upload
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :headers="headers"
                        accept=".jpg,.jpeg,.png"
                        :show-file-list="false"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess"
                        :before-upload="beforeUpload">
                        <img v-if="form.pic" :src="form.pic" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div class="" slot="tip">
<!--                            请上传-->
<!--                            大小不超过 <b style="color: #f56c6c">5MB</b>-->
<!--                            格式为 <b style="color: #f56c6c">jpg</b>-->
<!--                            的文件-->
                            照片要求：<br>
                            1.尺寸要求（像素）：540*700<br>
                            2.分辨率：350dpi<br>
                            3.头像占比：不大于宽度的50%<br>
                            4.白色背景无边框，人像清晰<br>
                            5.照片文件大小不超过5MB
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button :loading="buttonLoading" type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="title" :visible.sync="showPrintBox" width="1000px">
            <div id="printBox">
                <div style="display: flex;justify-content: center;align-items: center;flex-direction: column">
                    <div
                        style="text-align: center;font-size:36px;font-weight: 900; border-bottom: 3px solid;padding-bottom: 20px;width: 794px">
                        上海市出入境证件数字相片采集回执
                    </div>
                    <div style="display: flex;width: 794px;margin-top: 10px;">
                        <div style="display: flex;flex-direction: column">
                            <p style="font-size: 14px;text-align: left">
                                您的相片符合《中华人民共和国出入境证件数字相片技术要求》，申办证件类型为：<span
                                style="color: #D71618">护照或港澳台通行证</span>，相片图像号为：{{
                                    printItem.bizCode
                                }}。请持此回执及身份证、户口簿原件尽快到户口所在地出入境管理部门申办（本回执及数字相片1年内有效）。
                            </p>
                            <div style="margin-top: 10px">
                                <img id="code">
                                <!--                                <div>{{ printItem.id }}</div>-->
                            </div>
                            <div style="font-size: 12px;">
                                <div style="font-weight: 900;text-align: left">
                                    重要提示：
                                </div>
                                <p>
                                    您的满意是证照采集行业健康发展的有效保障。
                                </p>
                                <p>
                                    证照采集行业应自觉遵守《证照用数字相片采集行业（照相馆）行为规范》积极争创
                                    “诚信证照采集单位”：
                                </p>
                                <p style="text-indent: 1em">
                                    <i class="iconfont icon-a-wujiaoxingxingxing" style="font-size: 12px"></i>拍照时不得化浓妆，不得修改证件照面部特征、轮廓和五官；
                                </p>
                                <p style="text-indent: 1em;">
                                    <i class="iconfont icon-a-wujiaoxingxingxing" style="font-size: 12px"></i>收费应明码标价，列出各项明细，在顾客接受服务前协商好价格;
                                </p>
                                <p style="text-indent: 1em;">
                                    <i class="iconfont icon-a-wujiaoxingxingxing" style="font-size: 12px"></i>本回执即拍即取，不得额外收取回执加急费用；
                                </p>
                                <p style="text-indent: 1em;">
                                    <i class="iconfont icon-a-wujiaoxingxingxing" style="font-size: 12px"></i>不得假借政府名义“xx指定（定点）证照采集单位”等宣传误导群众。
                                </p>
                                <p>
                                    本公司将建立证照采集行业“诚信档案”，凡是被群众实名投诉的，一经核实，将录在案并按有关规定进行处理。客服及投诉电话：18017671105
                                </p>
                            </div>
                            <div style="font-size: 12px">
                                <div style="font-weight: 900;text-align: left;margin-top: 10px">
                                    特别声明：
                                </div>
                                <p>
                                    秉承“用户便利为根本，证照满意为目标”的运营理念，为构建社会化证照采集服务秩序，建立行业自律机制和竞争机制，服务价格市场竞争调节，采集单位自主经营、自负盈亏，其经营行为与本服务单位无关。
                                </p>
                                <!--                                <div style="display: flex;justify-content: space-between;margin-top: 20px">-->
                                <!--                                    <div>-->
                                <!--                                        采集单位：___________-->
                                <!--                                    </div>-->
                                <!--                                    <div>-->
                                <!--                                        采集时间：{{ printItem.uploadTime }}-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                            </div>

                        </div>
                        <div style="display: flex;flex-direction: column;margin-left: 40px">
                            <div>
<!--                                <el-image :src="baseUrl+printItem.oriFilePath" style="width: 150px;"/>-->
                                <ImagePreview width="150px" :src="printItem.zzFilePath">
                                </ImagePreview>
                            </div>
                            <div>
                                请确认本相片是否您本人，
                                且生物特征没有被 修改？
                            </div>
                            <div style="margin-top: 30px;">
                                签名：__________
                            </div>
                        </div>
                    </div>
                    <div class="cut-line" style="width: 100%;margin: 20px;font-size: 15px">
                        <div><i class="iconfont icon-jiandao"></i> <span
                            style="margin:0 10px">请沿线剪开并妥善保存</span></div>
                    </div>


                    <!--                    <div class="dashed-line">-->
                    <!--                        <i class="iconfont icon-jiandao" style="font-size: 13px"></i><span style="margin-left: 10px">请沿线剪开并妥善保存</span>-->
                    <!--                    </div>-->
                    <!--                    <el-divider><i class="iconfont icon-jiandao" style="font-size: 13px"></i><span style="margin-left: 10px">请沿线剪开并妥善保存</span></el-divider>-->

                    <div
                        style="width: 794px;border: #1a1a1a solid 2px;display: flex;padding: 10px;justify-content: space-between">
                        <div
                            style="display: flex;flex-direction: column;align-items: flex-start;font-size: 12px;justify-content: center">
                            <div style="font-weight: 900">相片信息</div>
                            <div>图像号：{{ printItem.bizCode }}</div>
                            <div>采集单位：_____________</div>
                            <div>采集时间：{{ printItem.uploadTime }}</div>
                        </div>
                        <div style="display: flex">
                            <div
                                style="display: flex;flex-direction: column;justify-content: center;font-size: 12px;color: #E60012;margin-right: 10px">
                                <div>扫描二维码下载相片</div>
<!--                                <div>（2024-03-18前有效）</div>-->
                                <div>可用于出入境管理局办理业务</div>
                            </div>
                            <div>
                                <div ref="qrCodeDiv"></div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-top: 30px;display: flex;justify-content: space-between;width: 200px"
                         id="no-print" class="no-print">
                        <div>
                            <el-button @click="print" size="mini" type="primary">打印</el-button>
                        </div>
                        <div>
                            <el-button @click="close" size="mini">关闭</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {query, add, update, del, get, upload} from '@/api/crud'
import * as api from '@/api/api'
import {Notification, MessageBox, Message, Loading} from 'element-ui'
import * as strUtil from '@/utils/strUtil'
import QRCode from 'qrcodejs2';
import JsBarcode from 'jsbarcode'
import print from 'print-js'
// import print from '@/utils/print'
import store from '@/store'
import {checkBasePhoto, getConfig, uploadPhotoByShootCode} from "@/api/api";

export default {
    data() {
        return {
            checkFlag:"N",
            loading:true,
            buttonLoading:false,
            detailForm:{},
            photoTypeOptions: [{
                value: '2',
                label: '身份证'
            }, {
                value: '501',
                label: '出入境'
            },{
                value: '1',
                label: '社保卡'
            },{
                value: '503',
                label: '驾驶证'
            }],
            daterangeUploadTime: [],
            headers: {
                Authorization: "Bearer " + store.token
            },
            baseUrl: process.env.VUE_APP_FILE_SERVER_URL,
            uploadUrl: process.env.VUE_APP_BASE_API + "/api/v1/sbkItem/uploadByQrCode",
            tableData: [],
            ids: [],
            showPrintBox: false,
            src: "",
            cjTime: "",
            printItem: {},
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            photoStatusList: [],
            // 总条数
            total: 0,
            // 岗位表格数据
            postList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                params: {},
                pageNum: 1,
                pageSize: 10,
                photoStatus: undefined,
                qrCode: undefined,
                orderByColumn: "updateTime",
                isAsc: "desc",
                // postCode: undefined,
            },
            openDetail:false,
            // 表单参数
            form: {
                qrCode: "",
                pic: "",

            },
            rules: {
                qrCode: [
                    {required: true, message: "拍摄码不能为空", trigger: "blur"},
                    {len: 8, message: "拍摄码长度为8", trigger: "blur"}
                ],
                pic: [
                    {required: true, message: "照片不能为空", trigger: "blur"}
                ],
                photoType: [
                    {required: true, message: "照片类型不能为空", trigger: "blur"}
                ],
            },
        };
    },
    created() {
        this.handleQuery();
        api.getDicts("sbk_photo_status").then(res => {
            this.photoStatusList = res.data
        })
        // api.getConfig("checkPhotoFlag").then(res=>{
        //     this.checkFlag=res.data
        // })
    },
    mounted() {
        // this.bindQRCode("2342432")
    },
    methods: {
        showDetail(row){
            this.openDetail=true
            this.detailForm=row
        },
        // changeCodeValue(v){
        //   this.form.qrCode=v;
        // },
        bindQRCode: function (val) {
            this.$refs.qrCodeDiv.innerHTML = ""
            new QRCode(this.$refs.qrCodeDiv, {
                text: val,
                width: 75,
                height: 75,
                // colorDark: "#333333", //二维码颜色
                // colorLight: "#ffffff", //二维码背景色
                // correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
            })
        },
        bindCode: function (num) {
            JsBarcode("#code", num, {
                format: "CODE128",//条形码的格式
                width: 1,//线宽
                height: 40,//条码高度
                lineColor: "#000",//线条颜色
                displayValue: true,//是否显示文字
                fontSize: 12,
                margin: 1//设置条形码周围的空白区域
            })
        },
        printBox(item) {
            if (item.photoStatus !== 99 && item.photoStatus !== 41) {
                this.$message.error('照片未完成')
                return;
            }
            this.showPrintBox = true
            this.printItem = item
            // this.src = this.baseUrl + item.oriFilePath
            // this.cjTime = item.uploadTime
            this.$nextTick(function () {
                this.bindCode(item.bizCode);
                this.bindQRCode(this.baseUrl + item.zzFilePath);
            })
        },
        close() {
            this.showPrintBox = false
        },

        print() {
            let that = this
            this.showPrintBox = false
            // let focuser = setInterval(() => window.dispatchEvent(new Event('focus')), 500)
            print({
                printable: 'printBox',
                type: 'html',
                // header: '打印回执',
                scanStyles: true,
                ignoreElements: ['no-print'],
                targetStyles: ['*'],
                font_size: '',
                css: [
                    "//at.alicdn.com/t/c/font_4001040_r4k0j3ifgb.css"
                ]
                // onPrintDialogClose: () => {
                //     clearInterval(focuser)
                //     that.close()
                // }
            })
        },
        handlePreview() {

        },
        handleRemove() {

        },
        beforeUpload(file) {
            let that = this
            const isLtM = file.size / 1024 / 1024 < 5
            if (!isLtM) {
                this.$message.error('上传图片大小不能超过 5MB!')
                return false
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                that.form.pic = reader.result;
                this.$forceUpdate()

                // if(this.checkFlag=="Y"){
                //     let params={
                //         photoSource: 7,
                //         shootCode: this.form.qrCode,
                //         photoType:this.form.photoType,
                //         pic: this.subStr(this.form.pic, 'base64,')
                //     }
                //     api.checkBasePhoto(params)
                // }
            }
            return false; // 取消默认的上传行为
        },
        handleSuccess(response, file, fileList) {
            console.log('上传成功1111111111111');
            console.log(response);
        },
        getTip(row) {
            if (row.photoStatus == 12) {
                return row.csMessage
            } else if (row.photoStatus == 24) {
                return row.rejectContent
            } else if (row.photoStatus == 32) {
                return row.rxbdMsg
            } else if (row.photoStatus == 42) {
                return row.kzxMsg
            }
            return ""
        },
        // onMessage(ee){
        //   alert(ee)
        // },
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            console.info(this.ids)
            this.single = selection.length != 1
            this.multiple = !selection.length
        },
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                qrCode: "",
                pic: ""
            };
            this.resetForm('form')
        },
        resetQuery() {
            this.daterangeUploadTime = [];
            this.open = false
            this.resetForm("queryForm");
            this.handleQuery(true);
        },
        handleCurrentChange(val) {
            this.queryParams.pageNum = val;
            this.handleQuery();
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val;
            this.handleQuery();
        },
        handleQuery(val) {
            this.queryParams.params = {};
            if (val == true) {
                this.queryParams.pageNum = 1
            }
            if (null != this.daterangeUploadTime && '' != this.daterangeUploadTime) {
                this.queryParams.params["beginUploadTime"] = this.daterangeUploadTime[0];
                this.queryParams.params["endUploadTime"] = this.daterangeUploadTime[1];
            } else {
                // this.queryParams.params["beginUploadTime"] = null
                // this.queryParams.params["endUploadTime"] = null
            }
            this.loading=true
            query(this.queryParams).then((res) => {
                this.tableData = res.rows;
                this.total = res.total;
            }).finally(()=>{
                this.loading=false
            })
        },
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids
            get(id).then(res => {
                this.form = res.data;
                this.open = true;
                this.title = "修改";
            });
        },
        subStr(input, str) {
            let startIndex = input.indexOf(str) + str.length
            let result = input.substring(startIndex); // 从该位置开始截取
            return result
        },
        submitForm() {
            // if (this.qrCode == "" || this.qrCode.length != 8) {
            //     Message({message: "拍摄码不正确", type: 'error'})
            //     return
            // }
            // if (this.pic == "") {
            //     Message({message: "请选择照片", type: 'error'})
            //     return
            // }
            this.$refs["form"].validate(async valid => {
                if (valid) {
                    this.buttonLoading = true
                    try {
                        let params={
                            // checkFlag:this.checkFlag,
                            photoSource: 7,
                            shootCode: this.form.qrCode,
                            photoType:this.form.photoType,
                            pic: this.subStr(this.form.pic, 'base64,')
                        }
                        // await api.checkBasePhoto(params)

                        let res = await api.uploadPhotoByShootCode(params)
                        Message({message: "上传成功", type: 'success'})
                        this.resetQuery()
                    } finally {
                        this.buttonLoading=false
                    }
                }
            });
        },
        handleDel(row) {
            const ids = row.id || this.ids;
            this.$confirm(`确定删除[${ids}]吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                del(ids).then((res) => {
                    if (res.code == 200) {
                        this.$message({message: res.msg, type: 'success'})
                        this.handleQuery()
                    }
                });
            })
        },
        handleUpload(row) {
            const ids = row.id || this.ids;
            this.$confirm(`确定上传[${ids}]吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                upload(ids).then((res) => {
                    if (res.code == 200) {
                        // this.$message({message: res.msg, type: 'success'})

                        let msg = "成功上传:<font color='red'>" + res.data.successCnt + "</font>张照片</br>"
                        if (res.data.errorIdCards != '') {
                            msg += "失败身份证:" + res.data.errorIdCards
                        }
                        this.$alert(msg, '提示', {
                            confirmButtonText: '确定',
                            dangerouslyUseHTMLString: true
                        });
                        this.handleQuery()
                    }
                });
            })
        },
        handleExport() {
            this.download('/personinfo/export', {
                ...this.queryParams
            }, `${this.$dateUtil.getFullDateTime()}.xlsx`)
        },
        handleExportPhoto() {
            this.download('/personinfo/exportPhoto', {
                ...this.queryParams
            }, `${this.$dateUtil.getFullDateTime()}.zip`)
        },
    },
}
</script>

<style lang="scss">
* {
    font-family: Alibaba PuHuiTi;
}


.cut-line {
    display: flex;
    align-items: center;
    border-bottom: 2px dashed black; /* 分割线样式 */
    position: relative; /* 设定相对定位 */
}

.cut-line div {
    position: absolute; /* 设定绝对定位 */
    top: -9px; /* 文字相对于分割线的位置 */
    left: 20%;
    background-color: white; /* 文字的背景色 */
}

//.cut-line::after {
//    content: "Content"; /* 伪元素显示的文字 */
//    position: absolute; /* 设定绝对定位 */
//    top: -10px; /* 文字相对于分割线的位置 */
//    left: 50%; /* 文字相对于父元素的位置 */
//    transform: translateX(-50%); /* 纠正位置偏移 */
//    padding: 0 15px; /* 文字的左右内边距 */
//    background-color: white; /* 文字的背景色 */
//}


.star-five {
    margin: 50px 0;
    position: relative;
    display: block;
    color: red;
    width: 0px;
    height: 0px;
    border-right: 100px solid transparent;
    border-bottom: 70px solid red;
    border-left: 100px solid transparent;
    -moz-transform: rotate(35deg);
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    -o-transform: rotate(35deg);
}

.star-five:before {
    border-bottom: 80px solid red;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -45px;
    left: -65px;
    display: block;
    content: '';
    -webkit-transform: rotate(-35deg);
    -moz-transform: rotate(-35deg);
    -ms-transform: rotate(-35deg);
    -o-transform: rotate(-35deg);

}

.star-five:after {
    position: absolute;
    display: block;
    color: red;
    top: 3px;
    left: -105px;
    width: 0px;
    height: 0px;
    border-right: 100px solid transparent;
    border-bottom: 70px solid red;
    border-left: 100px solid transparent;
    -webkit-transform: rotate(-70deg);
    -moz-transform: rotate(-70deg);
    -ms-transform: rotate(-70deg);
    -o-transform: rotate(-70deg);
    content: '';
}

.font30 {
    font-size: 30px;
}

.opt-btn {
    display: flex;
    margin-bottom: 15px;
}

.search-content {
    display: flex;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

p {
    text-align: left;
    text-indent: 2em; /* 这里的"em"表示相对于当前元素的字体大小 */
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
